#root {
  margin: 0;
  padding: 0;
  width: auto;
}

img {
  border-radius: 10px;
}

.context-img {
  width: 100%;
}

footer {
  display: flex;
  flex-direction: column;
  background-color: #28282a;
  font-size: smaller;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.css-gdlw0t-MuiListItem-root {
 padding: 0 !important;
 margin: 0 !important; 
} 

container {
  margin-bottom: 20px;
}

.entry-content {
  display: block;
}

.grid-row {
  display: flex;

}

.MuiCard-root {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
}

.MuiCardContent-root:last-child{
  height: 140px;
}


@media (max-width: 900px) {
  .context-img {
    width: 100%;
  }
  .entry-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MuiCardContent-root:last-child{
    height: auto;
  }
  }